import { DiscountRate } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * カタログマスタに関する機能を提供します.
 */
class DiscountRateService {
  /**
   * 初期状態の DiscountRate
   */
  public get defaultDiscountRate(): DiscountRate {
    return {
      guid: "",
      name: "",
      displayOrder: 0,
      rate: 0,
      lowerLimit: 0,
      upperLimit: 0,
      createdAt: "",
      updatedAt: "",
    } as DiscountRate;
  }

  /**
   * すべてのジャンプアップ割引率を取得して返します.
   * @returns すべてのジャンプアップ割引率
   */
  public async allDiscountRates() {
    const response = await sdk.discountRates();
    if (response?.discountRates) {
      return response.discountRates as DiscountRate[];
    }
    return [] as DiscountRate[];
  }

  /**
   * 指定したGUIDに一致するジャンプアップ割引率を取得して返します.
   * @param guid ジャンプアップ割引率のGUID
   * @returns ジャンプアップ割引率
   */
  public async getDiscountRate(guid: string) {
    const response = await sdk.discountRate({
      discountRateGuid: guid,
    });
    return response.discountRate as DiscountRate;
  }

  /**
   * ジャンプアップ割引率を登録します.
   * @param input ジャンプアップ割引率の登録情報
   * @returns 登録完了後のジャンプアップ割引率
   */
  public async createDiscountRate(input: DiscountRate) {
    try {
      const response = await sdk.createDiscountRate({
        createDiscountRateInput: {
          name: input.name,
          lowerLimit: input.lowerLimit,
          upperLimit: input.upperLimit,
          rate: input.rate,
          displayOrder: input.displayOrder,
        },
      });

      if (response?.createDiscountRate) {
        return response.createDiscountRate;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * ジャンプアップ割引率を更新します.
   * @param input ジャンプアップ割引率の更新情報
   * @returns 更新完了後のジャンプアップ割引率
   */
  public async updateDiscountRate(input: DiscountRate) {
    try {
      const response = await sdk.updateDiscountRate({
        updateDiscountRateInput: {
          guid: input.guid,
          name: input.name,
          lowerLimit: input.lowerLimit,
          upperLimit: input.upperLimit,
          rate: input.rate,
          displayOrder: input.displayOrder,
        },
      });

      if (response?.updateDiscountRate) {
        return response.updateDiscountRate;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * ジャンプアップ割引率を削除します.
   * @param input ジャンプアップ割引率の削除情報
   * @returns 削除したジャンプアップ割引率
   */
  public async deleteDiscountRate(input: DiscountRate) {
    try {
      const response = await sdk.deleteDiscountRate({
        deleteDiscountRateInput: {
          guid: input.guid,
        },
      });

      if (response?.deleteDiscountRate) {
        return response.deleteDiscountRate;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new DiscountRateService();
