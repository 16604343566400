










import SubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import DiscountRateList from "@/components/organisms/discount_rate/DiscountRateList.vue";

export default {
  components: {
    SubPageTemplate,
    DiscountRateList,
  },
};
