
















































import { Component, Ref, Vue } from "vue-property-decorator";
import { NotificationState } from "@/store/modules/notification";
import discountRateService from "@/service/discountRateService";
import DiscountRateEditDialog from "@/components/organisms/discount_rate/DiscountRateEditDialog.vue";
import DiscountRateDeleteDialog from "@/components/organisms/discount_rate/DiscountRateDeleteDialog.vue";
import { DiscountRate } from "@/graphql/client";

@Component({ components: { DiscountRateEditDialog, DiscountRateDeleteDialog } })
export default class DiscountRateList extends Vue {
  @Ref() readonly editDialog!: DiscountRateEditDialog;
  @Ref() readonly deleteDialog!: DiscountRateDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  items: DiscountRate[] = [];
  headers = [
    {
      text: "名称",
      value: "name",
    },
    {
      text: "金額（下限）",
      value: "lowerLimit",
      width: "15%",
    },
    {
      text: "金額（上限）",
      value: "upperLimit",
      width: "15%",
    },
    {
      text: "割引率",
      value: "rate",
      width: "10%",
    },
    {
      text: "表示順",
      value: "displayOrder",
      width: "10%",
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];
  loadingDataGrid = false;

  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    discountRateService.allDiscountRates().then((items) => {
      this.items = items;
      this.loadingDataGrid = false;
    });
  }
  /**
   * ジャンプアップ割引率編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(discountRateService.defaultDiscountRate);
  }

  /**
   * ジャンプアップ割引率編集ダイアログを表示します.
   */
  public openEditDialog(item: DiscountRate): void {
    this.editDialog.open(item);
  }

  /**
   * ジャンプアップ割引率編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: DiscountRate): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("ジャンプアップ割引率を更新しました！");
  }

  /**
   * ジャンプアップ割引率編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: DiscountRate): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("ジャンプアップ割引率を登録しました！");
  }

  /**
   * ジャンプアップ割引率削除ダイアログを表示します.
   */
  public openDeleteDialog(item: DiscountRate): void {
    this.deleteDialog.open(Object.assign({}, item));
  }

  /**
   * ジャンプアップ割引率削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: DiscountRate): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("ジャンプアップ割引率を削除しました！");
  }
}
