






















































































































import { DiscountRate } from "@/graphql/client";
import { Component, Ref, Emit, Mixins } from "vue-property-decorator";
import discountRateService from "@/service/discountRateService";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import MixinValidator from "@/components/mixins/discount_rate/MixinDiscountRateEditDialogValidator.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";

@Component({
  components: {
    ProgressLinear,
  },
})
export default class DiscountRateEditDialog extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly itemForm!: HTMLFormElement;
  //---------------------------
  // data
  //---------------------------
  isOpen = false;
  isProgressing = false;
  item: DiscountRate = discountRateService.defaultDiscountRate;
  valid = false;
  //---------------------------
  // mounted
  //---------------------------
  //---------------------------
  // computed
  //---------------------------
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の登録日時を取得
   */
  get formattedCreatedAt(): string {
    return this.formatDateTime(this.item.createdAt);
  }
  /**
   * 「YYY-MM-DD HH:mm:ss」形式の更新日時を取得
   */
  get formattedUpdatedAt(): string {
    return this.formatDateTime(this.item.updatedAt);
  }

  //---------------------------
  // methods
  //---------------------------
  async fetchData(item: DiscountRate): Promise<void> {
    this.item = discountRateService.defaultDiscountRate;
    if (item.guid) {
      const result = await discountRateService.getDiscountRate(item.guid);
      if (result) {
        this.item = result;
      }
    }
  }
  /**
   * ダイアログを表示します.
   */
  public async open(item: DiscountRate): Promise<void> {
    await this.fetchData(item);

    this.isProgressing = false;
    this.isOpen = true;
  }

  /**
   * ダイアログを閉じます.
   */
  public close(): void {
    this.$v.$reset();
    this.isProgressing = false;
    this.isOpen = false;
  }

  /**
   * 入力内容を保存します.
   */
  private save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      if (this.item.guid) {
        //update
        discountRateService
          .updateDiscountRate(this.item)
          .then((result) => {
            this.isProgressing = false;
            this.notifyUpdateSuccess(result as DiscountRate);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("ジャンプアップ割引率を更新できませんでした.");
          });
      } else {
        //create
        discountRateService
          .createDiscountRate(this.item)
          .then((result) => {
            this.isProgressing = false;
            this.notifyCreateSuccess(result as DiscountRate);
          })
          .catch((err) => {
            console.error(err);
            this.notifyError("ジャンプアップ割引率を登録できませんでした.");
          });
      }
    }
  }

  /**
   * 登録成功
   */
  @Emit("onCreateSuccess")
  public notifyCreateSuccess(created: DiscountRate): DiscountRate {
    return created;
  }
  /**
   * 更新成功
   */
  @Emit("onUpdateSuccess")
  public notifyUpdateSuccess(updated: DiscountRate): DiscountRate {
    return updated;
  }
  /**
   * 失敗
   */
  @Emit("onError")
  public notifyError(message: string): string {
    return message;
  }
}
