
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import {
  required,
  numeric,
  decimal,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";

@Component
export default class MixinDiscountRateEditDialogValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //名称
      name: { required },
      //表示順
      displayOrder: { required, numeric },
      //下限
      lowerLimit: { required, numeric },
      //上限
      upperLimit: { required, numeric },
      //割引率
      rate: {
        required,
        decimal,
        minValue: minValue(0),
        maxValue: maxValue(1),
      },
    },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * 名称の入力エラーメッセージを返します.
   */
  get nameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.name?.$dirty) return errors;
    !this.$v.item.name?.required && errors.push("名称を入力してください.");
    return errors;
  }
  /**
   * 表示順入力エラーメッセージを返します.
   */
  get displayOrderErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.displayOrder?.$dirty) return errors;
    !this.$v.item.displayOrder?.required &&
      errors.push("表示順を入力してください.");
    !this.$v.item.displayOrder?.numeric &&
      errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * 金額（下限）入力エラーメッセージを返します.
   */
  get lowerLimitErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.lowerLimit?.$dirty) return errors;
    !this.$v.item.lowerLimit?.required &&
      errors.push("金額（下限）を入力してください.");
    !this.$v.item.lowerLimit?.numeric && errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * 金額（上限）入力エラーメッセージを返します.
   */
  get upperLimitErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.upperLimit?.$dirty) return errors;
    !this.$v.item.upperLimit?.required &&
      errors.push("金額（上限）を入力してください.");
    !this.$v.item.upperLimit?.numeric && errors.push("数値を入力してください.");
    return errors;
  }
  /**
   * 割引率入力エラーメッセージを返します.
   */
  get rateErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.rate?.$dirty) return errors;
    !this.$v.item.rate?.required && errors.push("割引率を入力してください.");
    !this.$v.item.rate?.decimal && errors.push("数値を入力してください.");
    !this.$v.item.rate?.minValue &&
      errors.push("0以上の数値を入力してください.");
    !this.$v.item.rate?.maxValue &&
      errors.push("1以下の数値を入力してください.");
    return errors;
  }
}
